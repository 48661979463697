import { RefHistorical } from '../../framework/infra'
import Spouse from './Spouse'
import SpouseHistoryUtil from './SpouseHistoryUtil'


export default class SpouseHistory extends RefHistorical {

    static ref = Spouse;


    removeById(spouseId) {
        this._list = this._list.filter(x=>x.id !== spouseId);
        return this._list;
    }
  
    sort() {
        this._list = this._list.sort((a,b)=> {
            if (a.ets === b.ets) return a.rts - b.rts;
            return a.ets - b.ets;
        });
        return this._list;
    }

    /**
     * Find legal spouse in Federal or Quebec jurisdiction
     * 
     * Find legal spouse in Quebec (Supplemental Pension Plans Act) jurisdiction:
     * 
     * Your spouse is the person who, at the date a determination of spousal status is required:
     * a) is married to you; or
     * b) if you are not married to anyone, a person of the opposite sex or same sex who has been living in a conjugal relationship with you for a period of not less than three years, or for a period of not less than one year if:
     * (i)  at least one child is born, or to be born, of your union;
     * (ii)  you have jointly adopted at least one child while living together in a conjugal relationship; or
     * (iii)  one of you has adopted at least one child of the other while living together in a conjugal relationship.
     * 
     * Find legal spouse in Federal (Pension Benefits Standards Act) jurisdiction:
     * 
     * Definition of spouse:
     * Your spouse is the person who, at the date a determination of spousal status is required:
     * a) if there is no person described in (b) below, is married to you or is party to a void marriage with you; or
     * b) has been cohabiting with you in a conjugal relationship for at least one year.
     * 
     * @param {"que" | "fed"} jurisdiction 
     * @returns {Spouse | undefined} the legal spouse or none
     */
    findSpouse(jurisdiction) {
        // schema: https://app.diagrams.net/#G152WFeUFv0Ya3pF7OEuzwdkrujteurNcX#%7B%22pageId%22%3A%223h1_zvnjCFUreUOw-FLl%22%7D
        if(jurisdiction === SpouseHistoryUtil.jurisdiction_que) return SpouseHistoryUtil.findSpouseQc(this._list);
        if(jurisdiction === SpouseHistoryUtil.jurisdiction_fed) return SpouseHistoryUtil.findSpouseFed(this._list);

        return undefined;
    }

    static definitions = { 
        
    }
}
