import { RefHistorical, RefEvent, Definition } from '../../framework/infra'
import { RefEvents } from '../../framework/infra/model';
import { Period } from '../../framework/utils';
import { employmentEventConfigs } from './EmploymentConfig'
import EmploymentConstraints from './EmploymentConstraints';
import EmploymentStatus from './EmploymentStatus'
import moment from 'moment'

export class EmploymentEvent extends RefEvent {
    constructor(data) {
        super(data);
        if (employmentEventConfigs[data?.code]?.automatedEffectiveDate && !this.ets) {
			this.ets = employmentEventConfigs[data?.code]?.automatedEffectiveDate.valueOf();
		}
    }
    get stsCode() { return (this.config.status ? 'EE-' + this.status.keyValue.toUpperCase() :'') }
    get selfContribAccepted() { return this.config.selfContribAccepted }
    get selfContribDeclined() { return this.config.selfContribDeclined };
    get config() { return { ...employmentEventConfigs['default'], ...employmentEventConfigs[this.code]}}
    get status() { return EmploymentStatus.types[employmentEventConfigs[this.code]?.status] ?? EmploymentStatus.default }
    get constraints() { return EmploymentConstraints }
    /**
     * Check if the event is expired
     * @param {*} date 
     * @param {*} employerJurisdiction 
     * @param {*} joinEvent 
     * @returns {boolean} true if:
     * - the event has an expiration
     * - and there is a participation join event, and the participation join event is not after this event.
     * - and this event's date + expiration weeks (for the jurisdiction) is past
     */
    isExpired(date, employerJurisdiction, joinEvent) {
        const expiration = this.config.expiration
        if(!expiration) return false;
        if (!joinEvent || joinEvent.ets > this.ets ) return false;

        if(typeof expiration === 'number') return moment(this.ets).add(expiration, 'weeks').isBefore(moment(date));
        if(expiration[employerJurisdiction]) return moment(this.ets).add(expiration[employerJurisdiction], 'weeks').isBefore(moment(date));    
    }

    //move to Participation messages
    static messages = [
        ['hrd', 'Hired', '', 'i'],
        ['hrdRem', 'Hired through remittance', '', 'i'],
        ['swi', 'SWI - Switched-In', '', 'i'],
        ['rtw', 'Returned to Work', '', 'i'],
        ['ynp', 'Receiving pension from different jurisdiction', '', 'i'],
        ['apr', 'Progressive Return', '', 'i'],
        ['lgcyTri', 'TRI – Transferred-In', '', 'i'],
        ['mer', 'MER – Working at Multiple Employers', '', 'i'],

        // Leave events
        ['mat', 'Maternity', '', 'i'],
        ['ppmat', 'Maternity Prepaid', '', 'i'],
        ['ltd', 'Long Term Disability', '', 'i'],
        ['lpp', 'Parental/Paternity', '', 'i'],
        ['lpl', 'Parental', '', 'i'],
        ['lpy', 'Paternity', '', 'i'],
        ['lcc', 'Compassionate care/Adoption', '', 'i'],
        ['lco', 'Compassionate care', '', 'i'],
        ['lad', 'Adoption Leave', '', 'i'],
        ['lst', 'Approved Medical Leave (ie: STD, CNESST)', '', 'i'],
        ['leo', 'Educational/Sabbatical/Other', '', 'i'],
        ['lso', 'Other Leave', '', 'i'],
        ['led', 'Educational Leave (Full Time Student)', '', 'i'],
        ['lsw', 'Suspended without pay', '', 'i'],
        ['susPay', 'Suspended with pay', '', 'i'],
        ['loa', 'Leave of Absence', '', 'i'],
        ['lms', 'Military Service', '', 'i'],
        ['lun', 'Unspecified Leave', '', 'i'],

        // Self contribution decision events
        ['dsc', 'Self Contribution Declined', '', 'i'],
        ['asc', 'Self Contribution Accepted', '', 'i'],
        ['nsc', 'Self Contribution (no response)', '', 'i'], 
        ['esc', 'No self contributions – Offer expired', '', 'i'],   

        //['TRM', 'Terminated', '', 'i', { status: 'trm' }],
        ['tlo', 'Lay-off (intention to rehire)', '', 'i'],
        ['tfq', 'Fired/Quit', '', 'i'],
        ['tnf', 'Employment Closed - No Earnings Reported', '', 'i'],
        ['tnc', 'Employment Closed - No Earnings Reported for more than one calendar year', '', 'i'],
        ['trd', 'Retired', '', 'i'],
        ['tde', 'Deceased', '', 'i'], //treat different
        ['tsw', 'SWO - Switched-Out', '', 'i'], 
        ['tro', 'TRO – Transferred-Out', '', 'i'], 
        ['tex', 'Leave Expired', '', 'i'], 
        
        ['stsConfAct', 'Active Status Confirmed', '', 'i'],
        ['stsConfDen', 'Active Status Denied', '', 'i'],
        ['stsConfInf60', 'Status Confirmed, Terminated < 60 days', '', 'i'],
        ['stsConfSup60', 'Status Confirmed, Terminated > 60 days', '', 'i'],

        ['rim', 'RIM - Not eligible: all contributions reimbursed to Employer', '', 'i'],
        ['actPen', 'Active & Pensioner - Accruing & receiving pension in different jurisdiction', '', 'i'],
        ['tclCan', 'Employment Closed - Eligibility period has expired', '', 'i'],
        ['penDt', 'Pension start Date', '', 'i'],
        ['psd', 'Payroll start date', '', 'i'],
        ['cDmd', 'Verify deemed calculations', '', 'i'],
        ['memS', 'Membership Sustained notice has been sent to employee and employer'],
        ['actAutDed', 'Employer notified to start deductions immediately', '', 'i'],
        ['fqToLayOff', 'Fired quit to lay-off flag event', '', 'i'],
    ]

    static definitions = {
        status: { abstract: true, ref: EmploymentStatus, text: 'Status' },
        selfContribAccepted:  { abstract: true, type: Definition.types.BOOLEAN, text: 'Self-Contribution Accepted' },
        selfContribDeclined:  { abstract: true, type: Definition.types.BOOLEAN, text: 'Self-Contribution Declined' },
        config: {abstract: true},
        messagesOption: {abstract: true},
        stsCode: {abstract: true},
    }

    static get messagesOption() {
        return Object.values(this.messages).filter(mess => !employmentEventConfigs[mess.key]?.isHidden).map(opt => ({key: opt.key, text: opt.text, value: opt}));
    }

    
}

export class EmploymentEvents extends RefEvents {

    constructor(data) {
        super(data, EmploymentConstraints);
    }

    get statusEvents() { return this.getFiltered(eve => eve.status) }

    /**
     * Sets the end timestamp of a list of historical items
     * Checks if the ignoreForEndTs attribute exists on historical items config:
     *  - If so, gets next historical item with status not equal to one of the values of ignoreForEndTs
     *    (skips over item with ignoreForEndTs)
     *  - If not, gets next historical item in list
     * 
     * When the next historical item is found, the current item's end timestamp is set to 
     * the next historical item's effective timestamp (ets) minus one day.
     */
    assignEndTs() { 
        this._list.forEach((elem, index, list) => {
            if (elem.reviewed) {
                return elem;
            } else if (elem.config?.ignoreForEndTs) {
                return elem.endTs = moment(
                    this.getNextStatus(list, elem, index)?.ets ?? '9999-12-31'
                ).subtract(1, 'day').valueOf();
            } else { 
                return elem.endTs = list[index+1] 
                    ? moment(list[index+1].ets).subtract(1, 'day').valueOf() 
                    : moment('9999-12-31').valueOf();
            }
        });
    }

    getNextStatus(list, element, index) {
        return index < list.length 
            ? list.slice(index+1).find(item => !element.config.ignoreForEndTs.includes(item.code))
            : undefined;
    }

    /**
     * 
     * @param {Period | undefined} period 
     * @returns leave events without self events:
     * - has events eligibleForSelfContribution (eligible for self contribution) or selfContribAccepted (Self Contribution Accepted) or selfContribDeclined (Self Contribution Declined, or No self contributions – Offer expired)
     * - that are followed by events eligibleForSelfContribution and not selfContribAccepted (Self Contribution Accepted) and not selfContribDeclined (Self Contribution Declined, or No self contributions – Offer expired)
     */
    getLeavesWithoutSelfEvents(period) {
        const events = period ? this.getDuring(period.timestampAtPeriodStart, period.timestampAtPeriodEnd) : this.all;
        
        return events
            .filter(
                (event) =>
                    event.status.eligibleForSelfContribution() ||
                    event.config.selfContribAccepted ||
                    event.config.selfContribDeclined
            )
            .filter(
                (event, index, list) =>
                    event.status.eligibleForSelfContribution() &&
                    !list[index + 1]?.config.selfContribAccepted &&
                    !list[index + 1]?.config.selfContribDeclined
            )
    }

    checkAcceptedContributionsForSelfLeaves(period) {
        let effectiveDatesOfLeaves = '';
        this.getLeavesWithoutSelfEvents(period)
            .filter(event => Period.create(event.effDt).isSameYear(period))
            .forEach((event, index, list) => {
            if (index === 0) {
                effectiveDatesOfLeaves += `${event.effDt}${
                    list.length === 1 ? "." : ""
                }`;
            } else if (list.length === index + 1) {
                effectiveDatesOfLeaves += ` and ${event.effDt}.`;
            } else {
                effectiveDatesOfLeaves += `, ${event.effDt}`;
            }
        });
        return effectiveDatesOfLeaves;
    }
    
    static ref = EmploymentEvent
}
