import MultipleResolution from "../../../../framework/components/taskValidation/MultipleResolution";

export const OnboardingTask = {
    desc: 'Onboarding',
    isMultipleResolution: true,
    /**
     * 
     * @param {Participation} participation 
     * @returns true if:
     * - the participation status is active: `ACT` (Enrolled)
     * - and the participation does not have legacy enrollment form event: not `formReceived` events: `enrRec` (Enrollment form received) or `rcvEnrlFrm` (Enrolment form received)
     */
    validation: (participation) => {return participation.status.isActive() && !participation.hasLegacyEnrollmentForm()},
    /**
     * 
     * @param {Participation} participation 
     * @returns true if:
     * - the participation has a `onbrd` (Onboarding - Complete) event
     * - the `onbrd` (Onboarding - Complete) event can show: the event's sub-event tasks are all validated
     */
    removeValidation: (participation) => {
        const onbrdEvent = participation.events.find(ev => ev.code === 'onbrd');
        return onbrdEvent && onbrdEvent.config.canShow({instance: participation, event: onbrdEvent});
    },
    form: <MultipleResolution code='onbrd' class='overflow-scroll' title="Onboarding"/>
}


