import moment from "moment";
import CalendarDetailResolution from "../../framework/components/taskValidation/RemittanceDetailError/CalendarDetailResolution";
import YesNoDetailResolution from "../../framework/components/taskValidation/RemittanceDetailError/YesNoDetailResolution";
import MidMonthAdjustment from "../../views/financial/remittance/warningfixers/MidMonthAdjustments";

export const RemittanceMessageConfig = {
    default: {},
    ltdToTerminated: {
        fixer : <CalendarDetailResolution 
            dateLabel="LTD End Date"
            taskSave={(employment, date, comment, params) => {
                const { periodEts, periodEndTs } = params;
                const events = employment.events.getAllDuringWithEndTs(
                    periodEts,
                    periodEndTs
                );
                const ltdEvent = events.find(event => event.status.isLtd());
                ltdEvent.endTs = moment(date).valueOf();
                ltdEvent.reviewed = true;
            }} 
            message='Enter the end date of the LTD benefit (Last day paid)'
            disableComment
            isEmployment
        />
    },
    ltdToTerminatedInfo : {
        fixer : <CalendarDetailResolution 
            dateLabel="LTD End Date"
            taskSave={(employment, date, comment, params) => {
                const { periodEts, periodEndTs } = params;
                const events = employment.events.getAllDuringWithEndTs(
                    periodEts,
                    periodEndTs
                );
                const ltdEvent = events.find(event => event.status.isLtd());
                ltdEvent.endTs = moment(date).valueOf();
                ltdEvent.reviewed = true;
            }} 
            message='Enter the end date of the LTD benefit (Last day paid)'
            disableComment
            isEmployment
        />
    },
    
    // NOTE: We do not want to delete this (APP-1397), will be fixed in the future
    // midMonthEnr: {
        // fixer: <MidMonthAdjustment type="MID"/>   
    // },

    midMonthRet: {
        fixer: <MidMonthAdjustment type='A60'/>
    },
    closeEarningAdj: {
        fixer : <YesNoDetailResolution 
            onYes={(participation, cmt) => participation.addEvent({code: 'adjTerm', cmt: cmt})} 
            onNo={(participation, cmt) => participation.addEvent({code: 'noAdjTerm', cmt: cmt})}
            message={'Has the adjustment been created?'}
        />,
        alwaysShow: true,
    },
    eligEarning: {
        fixer : <CalendarDetailResolution 
            dateLabel="Join Date" 
            taskSave={(participation, joinDt, comment) => 
                participation.addEvent({code: 'nrolFday', ets: moment(joinDt).valueOf(), cmt: comment})
            } 
            message='Enter the first day of work'
        />
    },
    noEarningsAt60: {
        alwaysShow: true
    },
    maternityEnded: {
        fixer: <CalendarDetailResolution 
                    definedDate='endDate'
                    dateLabel="Parental Date" 
                    taskSave={(employment, date, comment) => {
                        employment.addEvent({code: 'lpl', ets: moment(date), cmt: comment})
                    }}
                    message='Maternity leave has expired, status will change to Parental on this date.'
                    isEmployment
            />
    }

}