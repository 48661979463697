import { Service } from '../../framework'
import { getSafe } from '../../framework/utils/helper'

import { Participation} from '../../entities'
import { EmploymentEvent } from "../../entities/employment";
import { EmploymentService, MembershipService }  from '..'
import ParticipationBusiness from '../../business/ParticipationBusiness'
import { RefEvent } from '../../framework/infra'
import { setAPIError } from '../../hooks/useAPIError'
import moment from "moment";

class ParticipationService extends Service {
	constructor() {
        super(Participation, 'Participation', 'membership')
    }

	/**
	 * Check the impact of the participation update
	 * @param {Participation} participation The participation to update
	 * @returns {{ppIsNotEnrolled: boolean; hasAddedNrolPPEvent: boolean; nrolEventEffDate: number | undefined}} A flag to indicate the participation status (before the changes) is "Not Enrolled" (Potential or Eligible or Not Eligible),
	 * a flag to indicate the changes have added a "NROL" participation event, the date of the added "NROL" event
	 */
	async onBeforeUpdate(participation) {
		const existingMembership = await MembershipService.get(participation.person.id, { bypassCache: true, includeEmployments: true })
		const existingParticipation = existingMembership?.participations?.all?.find(pp => pp.no === participation.no)
		
		/** The current participation status (before the changes) is "Not Enrolled" */
		const ppIsNotEnrolled = Boolean(existingParticipation?.status?.isPotential?.()) || Boolean(existingParticipation?.status?.isEligiblePeriod?.());
		/** The changes have added a "NROL" participation event */
		const hasAddedNrolPPEvent =  Boolean(participation.eventStatuses?.latest?.config?.isEnrollEvent);

		return {ppIsNotEnrolled, hasAddedNrolPPEvent, nrolEventEffDate: participation?.eventStatuses?.latest?.ets};
	}

	/**
	 * Update the participation
	 * 
	 * Will check if we need to add a RTW event to the employments of the participation, and add it if needed
	 * @param {Participation} participation The participation to update
	 * @returns 
	 */
	async updateParticipation(participation) {
		const beforeUpdateChecks = await this.onBeforeUpdate(participation);

		const updatedParticipation = await this.__updateParticipation(participation)
		
		await this.onAfterUpdate(updatedParticipation, beforeUpdateChecks);

		return updatedParticipation;
	}

	/**
	 * After the participation update, add RTW events in the participation's employments if needed.
	 * @param {Participation} updatedParticipation 
	 * @param {{ppIsNotEnrolled: boolean; hasAddedNrolPPEvent: boolean; nrolEventEffDate: number | undefined}} beforeUpdateChecks 
	 */
	async onAfterUpdate(updatedParticipation, beforeUpdateChecks){
		const membershipAfterUpdate = await MembershipService.get(updatedParticipation.person.id, { includeEmployments: true })
		const participationAfterUpdate = membershipAfterUpdate?.participations?.all?.find(pp => pp.no === updatedParticipation.no)
		const {ppIsNotEnrolled, hasAddedNrolPPEvent, nrolEventEffDate} = beforeUpdateChecks;

		/** 
		 * Updates to do on the employments
		 * @type {Array<Promise<any>>}
		 */
		const employmentUpdates = [];

		participationAfterUpdate?.employments.all?.forEach(existingEmployment => {
			// Add a RTW event if necessary.
			// Rules:
			// - current status is On Leave - (Not Enrolled)
			// - We have added a NROL event
			// - There's not already a RTW or Hired event in the employment (after the On Leave event)
			// New event: will have same date as the NROL event
			if(ppIsNotEnrolled && hasAddedNrolPPEvent && nrolEventEffDate) {
				employmentUpdates.push(this.addRtwEvent(existingEmployment, nrolEventEffDate))
			}
		})

		if(employmentUpdates.length > 0) {
			await Promise.all(employmentUpdates);
		}

	}

	/**
	 * Add RTW event in the employment (with guessed date) if needed.
	 * 
	 * Rules:
	 * - current employment status is On Leave
	 * - There's not already a RTW or Hired event in the employment (after the On Leave event)
	 * @param {Employment} employment 
	 * @param {number} guessedDate 
	 */
	async addRtwEvent(employment, guessedDate) {
		/** The employment status is On Leave */
		const employmentIsOnLeave = Boolean(employment?.status?.isOnLeave?.());
		/**
		 * Date of the On Leave employment status event. For example, "2023-12-31".
		 * @type {string | undefined} 
		 */
		const onLeaveEventDate = employmentIsOnLeave && employment?.events?.statusEvents?.latest?.status?.key ===  employment?.status?.key ? employment?.events?.statusEvents?.latest?.effDt : undefined;

		/** The employment already has a RTW event after the OnLeave event */
		const hasRtwEventAfterLeave = employment.events?.statusEvents?.all?.some?.(ev => {
			return Boolean(ev.config?.isReturnToWork && onLeaveEventDate && moment(ev.effDt).isSameOrAfter(moment(onLeaveEventDate)))
		})

		/** The employment already has a Hired event after the OnLeave event */
		const hasHiredEventAfterLeave = employment.events?.statusEvents?.all?.some?.(ev => {
			return Boolean(ev.config?.isHiredEvent && onLeaveEventDate && moment(ev.effDt).isSameOrAfter(moment(onLeaveEventDate)));
		})

		if(employmentIsOnLeave && !hasRtwEventAfterLeave && !hasHiredEventAfterLeave) {
			const rtwEvent = new EmploymentEvent({ets: guessedDate, code: 'rtw', guessed: true});
			employment.addEvent(rtwEvent);
			return EmploymentService.update(employment);
		}
	}

	__updateParticipation(participation) {
		return this.persistence.update(this.moduleName + '_Update' + this.serviceName, participation, null ,{ppKey: participation.keyValue}).then(inst => {
			const response = this._setPromise(inst.keyValue, Promise.resolve(inst))
			this.invalidateCache();
			return response;
		})
	}

	get(key = '', options = {}) {
		const keys = Participation.getKeyValues(key) 
		return MembershipService.get(keys.membership, options).then(mem => {
			return getSafe(mem, 'participations.' + key)
		}).then(participation => {
			if (options.init !== false) this.init(participation)
			return participation
		})
	}
	
	getAll(options = {}) {
		return MembershipService.getMemberships(options).then(memberships => {
			return memberships.reduce((parts, mem) => {
				parts.pushList(mem.participations)
				return parts
			}, this.toRefList([]))
		})
	}

	getMemberParticipations(memberId = '', options = {}) {
		return MembershipService.get(memberId, options).then(mem => {
			return mem.participations
		})
	}

	deleteParticipation(participation){
        this.invalidateCache();
		return this.persistence
            .callApi(
                this.moduleName + "_DeleteParticipation", {}, { keys: participation.keyValue }
            )
            .catch((err) => {
                setAPIError(err);
                console.log(err);
                throw err;
            });;
	}
	
	async load(participation, options = {}) {
		for (const event of participation.events.all) {
			for (const pointer of event.pointers.all) {
				await pointer.load();
			}
		}
	}

	init(participation) {
		participation.events.sortEvents();
		return ParticipationBusiness.validate(participation)
	}

	transferEmployment(participation) {
        const etsHiredDt = participation.employments.last.getHiredEvent().ets;
        participation.employments.last.getHiredEvent().ets += 100;
        participation.employments.last.addEvent({
            code: "lgcyTri",
            ets: etsHiredDt + 10,
        });
        participation.employments._list[
            participation.employments.length - 2
        ].addEvent({ code: "tro", ets: etsHiredDt });
        EmploymentService.update(participation.employments.last).then(() =>
            EmploymentService.update(
                participation.employments._list[
                    participation.employments.length - 2
                ]
            )
        );
    }
}

const singelton = new ParticipationService()
export default singelton
