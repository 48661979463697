import moment from "moment";

/**
 * creditAdjustmentsCalculationsStartPeriod: the start period in which the credit adjustments calculations will be triggered
 * startDateWIntrPlan: the start period in which the "Waiting for Inter-plan transfer" task will show in history
 */

const config = {
    env: process.env.REACT_APP_ENV || "local",
    modules: {
        DEV: { unreleased: true },
        I18N: { unreleased: false }
    },
    launchPeriod: '202001',
    creditAdjustmentsCalculationsStartPeriod: '202208',
    totalOwingStartPeriod: '202301',
    startDateWIntrPlan: '202201', 
    epoch: '19000101',
    /**
     * Absolute date when plans merged
     * @type {'20230101'}
     */
    cutoff2023: '20230101',
    ageToRejoinPlan: (dateTs) => {
        let defaultAge = { que: 60, fed: 65 };
        let ages = [ { que: 71, fed: 71, cutoff: moment(config.cutoff2023).valueOf() } ]

        return ages.find(x=> dateTs >= x.cutoff) ?? defaultAge;
    },
    debug: ['dev', 'local', 'int', 'bef'].includes(process.env.REACT_APP_ENV)
}

export default config;
    

