//Class to apply various defined constraints on the employments events history
class EmploymentConstraints {

    constructor () {
        this.constraints = {
            /** Rule to check if the event was changed from fired/quit to lay-off
             * If so, add hidden flag event to show a task to the user 
             * @param {*} param0 {employment, draft (new event), event (previous event)}
             */
            firedQuitToLayOff: ({employment, draft, event}) => { 
                if (!draft || !employment || !event) return;
                const isPreviousFiredQuit = event.status.isFiredQuit();
                const isCurrentLayOff = draft.status.isLayOff();
                const hasFqToLayOff = employment.events.find(e => e.config.isFiredQuitToLayOff);

                if (isPreviousFiredQuit && isCurrentLayOff && !hasFqToLayOff) {
                    employment.addEvent({code: 'fqToLayOff', ets: draft.ets + 10, mts: Date.now()});
                }
            }
        };
    }

    apply(params) {
        for (const [key, constraint] of Object.entries(this.constraints)) {
            constraint(params);
        }
    }

}

export default new EmploymentConstraints();