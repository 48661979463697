import { Definition, RefEnum } from '../../../framework/infra'

export default class ParticipationStatus extends RefEnum {
    /** participation status is `TRM` (Participation Terminated) or `PEN` (Pensioner) or `DFR` (Deferred) or `DED` (Deceased) or `CAN` (Cancelled) */
    isClose() { return ['TRM', 'PEN', 'DFR', 'DED', 'CAN'].includes(this.key) }
    /** participation status is `NEG` (Not Eligible) */
    isIneligible() { return this.key === 'NEG' }
    /** participation status is `POT` (Not Enrolled) or `NEG` (Not Eligible) */
    isPotential() { return this.key === 'POT' || this.isIneligible() }
    /** participation status is `POT` (Not Enrolled) */
    isOnlyPotential(){ return this.key === 'POT'}
    /** participation status is `ACT` (Enrolled) */
    isActive() { return this.key === 'ACT' }
    /** participation status is `TRM` (Participation Terminated) or `DED` (Deceased) */
    isTerminated() { return ['TRM', 'DED'].includes(this.key) }
    /** participation status is `DED` (Deceased) */
    isDeceased() { return this.key === 'DED'}
    /** participation status is `PEN` (Pensioner) */
    isReceivingPension() { return this.key === 'PEN' }
    /** participation status is `SUP` (Suspended) */
    isPensionSuspended() { return this.key === 'SUP' }
    /** participation status is `DFR` (Deferred) */
    isDeferred() { return this.key === 'DFR' }
    /** participation status is `ACT` (Enrolled) or `ELI` (Eligible) */
    isEligible() { return this.isActive() || this.key === 'ELI' }
    /** participation status is `ELI` (Eligible) */
    isEligiblePeriod() { return this.key === 'ELI'}
    /** participation status is `CAN` (Cancelled) */
    isCancelled() { return this.key === 'CAN' }
    /**
     * Check if should sow message (should show if participation is not potential or cancelled or ineligible)
     * @returns true if status is:
     * - not potential: not `POT` (Not Enrolled) or `NEG` (Not Eligible)
     * - and not cancelled: not `CAN` (Cancelled)
     * - and not ineligible: not `NEG` (Not Eligible)
     */
    shouldShowMessage() {
        return !this.isPotential()
            && !this.isCancelled()
            && !this.isIneligible();
    }
    /**
     * Check if should sow task (should show if participation is not potential or cancelled or ineligible or terminated or receiving pension or pension suspended or eligible period)
     * @returns true if status is:
     * - not potential: not `POT` (Not Enrolled) or `NEG` (Not Eligible)
     * - and not cancelled: not `CAN` (Cancelled)
     * - and not ineligible: not `NEG` (Not Eligible)
     * - and not terminated: not `TRM` (Participation Terminated) or `DED` (Deceased)
     * - and not receiving pension: not `PEN` (Pensioner)
     * - and not pension suspended: not `SUP` (Suspended)
     * - and not eligible period : not `ELI` (Eligible)
     */
    shouldShowTask(){
        return !this.isPotential()
        && !this.isCancelled()
        && !this.isIneligible()
        && !this.isTerminated()
        && !this.isReceivingPension()
        && !this.isPensionSuspended()
        && !this.isEligiblePeriod()
    }


    static types = {
        /** POT: Not Enrolled */
        POT: new ParticipationStatus('POT', 'Not Enrolled'),
        /** ELI: Eligible */
        ELI: new ParticipationStatus('ELI', 'Eligible'),
        /** ACT: Enrolled */
        ACT: new ParticipationStatus('ACT', 'Enrolled'),
        /** PEN: Pensioner */
        PEN: new ParticipationStatus('PEN', 'Pensioner'),
        /** SUP: Suspended */
        SUP: new ParticipationStatus('SUP', 'Suspended'),
        /** TRM: Participation Terminated */
        TRM: new ParticipationStatus('TRM', 'Participation Terminated'),
        /** DFR: Deferred */
        DFR: new ParticipationStatus('DFR', 'Deferred'),
        /** NEG: Not Eligible */
        NEG: new ParticipationStatus('NEG', 'Not Eligible'),
        /** CAN: Cancelled */
        CAN: new ParticipationStatus('CAN', 'Cancelled'),
        /** DED: Deceased */
        DED: new ParticipationStatus('DED', 'Deceased')
    }
    static default = new ParticipationStatus('', '')
}
