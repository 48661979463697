import moment from "moment";

/** @type {"fed"} */
const jurisdiction_fed = "fed";
/** @type {"que"} */
const jurisdiction_que = "que";
/** @type {"both"} */
const jurisdiction_both = "both";

/** Sort list by ets and rts, asc
 * @returns a copy of the list, sorted.
 */
function sortByDateAsc(_list) {
  const list = [..._list].sort((a, b) => {
    if (a.ets === b.ets) return a.rts - b.rts;
    return a.ets - b.ets;
  });
  return list;
}

/** Sort list by ets and rts, desc
 * @returns a copy of the list, sorted.
 */
function sortByDateDesc(_list) {
  return sortByDateAsc(_list).reverse();
}

/**
 * Check if the 2 marital info are about the same person
 *
 *
 * @param {{noSin?: boolean; person?: {sin?: string; id?: string;}; unknownPerson?: {nameFirstLast?: string; dob?: string}}} maritalInfo1
 * @param {{noSin?: boolean; person?: {sin?: string; id?: string;}; unknownPerson?: {nameFirstLast?: string; dob?: string}}} maritalInfo2
 * @returns true if the 2 marital info have the same person SIN or same person ID or same unknown person name and dob
 */
function isSamePerson(maritalInfo1, maritalInfo2) {
  return (
    // same SIN
    (maritalInfo1?.person?.sin &&
      maritalInfo2?.person?.sin &&
      maritalInfo1.person.sin === maritalInfo2.person.sin) ||
    // same person ID
    (maritalInfo1?.person?.id &&
      maritalInfo2?.person?.id &&
      maritalInfo1.person.id === maritalInfo2.person.id) ||
    // same unknown person (name and dob)
    (maritalInfo1?.noSin &&
      maritalInfo2?.noSin &&
      maritalInfo1?.unknownPerson?.nameFirstLast?.trim?.().toLowerCase?.() &&
      maritalInfo2?.unknownPerson?.nameFirstLast?.trim?.().toLowerCase?.() &&
      maritalInfo1.unknownPerson.nameFirstLast.trim().toLowerCase() ===
        maritalInfo2.unknownPerson.nameFirstLast.trim().toLowerCase() &&
      maritalInfo1.unknownPerson.dob &&
      maritalInfo2.unknownPerson.dob &&
      maritalInfo1.unknownPerson.dob === maritalInfo2.unknownPerson.dob)
  );
}

/**
 *
 * @param {string | null | undefined} mSts the marital status
 * @returns true if the marital status is single
 */
function isSingleNotUndefined(mSts) {
  return mSts === "single";
}
/**
 *
 * @param {string | null | undefined} mSts the marital status
 * @returns true if the marital status is single or there is no marital status
 */
function isSingle(mSts) {
  return !mSts || isSingleNotUndefined(mSts);
}

/**
 *
 * @param {string | null | undefined} mSts the marital status
 * @returns true if the marital status is married
 */
function isMarried(mSts) {
  return mSts === "married";
}

/**
 *
 * @param {string | null | undefined} mSts the marital status
 * @returns true if the marital status is commonLaw
 */
function isCommonLaw(mSts) {
  return mSts === "commonLaw";
}

/**
 *
 * @param {string | null | undefined} mSts the marital status
 * @returns true if the marital status is separated
 */
function isSeparated(mSts) {
  return mSts === "separated";
}

/**
 *
 * @param {string | null | undefined} mSts the marital status
 * @returns true if the marital status is divorced
 */
function isDivorced(mSts) {
  return mSts === "divorced";
}

/**
 *
 * @param {string | null | undefined} mSts the marital status
 * @returns true if the marital status is widow
 */
function isWidowed(mSts) {
  return mSts === "widow";
}

/**
 * Check if the marital info 1 is the same day or after marital info 2
 *
 * @param {{ets: number | string; rts: number | string;}} maritalInfo1
 * @param {{ets: number | string; rts: number | string;}} maritalInfo2
 */
function isSameDayOrAfter(maritalInfo1, maritalInfo2) {
  const { date1, date2 } =
    maritalInfo1.ets === maritalInfo2.ets
      ? { date1: maritalInfo1.rts, date2: maritalInfo2.rts }
      : { date1: maritalInfo1.ets, date2: maritalInfo2.ets };
  return moment(date1).isSameOrAfter(moment(date2), "day");
}

/**
 * Find legal spouse in Federal (Pension Benefits Standards Act) jurisdiction
 *
 * Definition of spouse:
 * Your spouse is the person who, at the date a determination of spousal status is required:
 * a) if there is no person described in (b) below, is married to you or is party to a void marriage with you; or
 * b) has been cohabiting with you in a conjugal relationship for at least one year.
 *
 * Note: For the legal spouse - if the person at the time of event is married (latest date) this is the legal spouse that wins (need to factor in the chronological order of events)
 * See https://sysem.atlassian.net/browse/APP-1321?focusedCommentId=13164
 */
const findSpouseFed = (list) => {
  // schema: https://app.diagrams.net/#G152WFeUFv0Ya3pF7OEuzwdkrujteurNcX#%7B%22pageId%22%3A%223h1_zvnjCFUreUOw-FLl%22%7D

  /** List of spouses in federal or both jurisdictions, ordered by date desc (oldest first) */
  const fedSpouses = sortByDateDesc(list).filter(
    (spouse) =>
      spouse.jurisdiction === jurisdiction_fed ||
      spouse.jurisdiction === jurisdiction_both
  );

  const hasNoSpouse = fedSpouses.length === 0;
  if (hasNoSpouse) {
    return undefined;
  }

  // find if the first one (in the list ordered by date desc) is where status is single
  const lastSingle = isSingleNotUndefined(
    fedSpouses[fedSpouses.length - 1]?.mSts
  )
    ? fedSpouses[fedSpouses.length - 1]
    : undefined;
  if (lastSingle) {
    return lastSingle;
  }

  // find the first one (in the list ordered by date desc) where status is common law
  // and does not have a widowed/divorced/single status after with the same person
  // and has not married anyone after
  const commonLawSpouse = fedSpouses.find((currentMaritalInfo) => {
    /** has divorced or widowed the same person after */
    const hasDivorcedOrWidowedAfter = fedSpouses.some(
      (otherMaritalInfo) =>
        isSameDayOrAfter(otherMaritalInfo, currentMaritalInfo) &&
        isSamePerson(currentMaritalInfo, otherMaritalInfo) &&
        (isWidowed(otherMaritalInfo?.mSts) ||
          isDivorced(otherMaritalInfo?.mSts))
    );
    /** has married (same person or another person) after */
    const hasMarriedAfter = fedSpouses.some(
      (otherMaritalInfo) =>
        isSameDayOrAfter(otherMaritalInfo, currentMaritalInfo) &&
        isMarried(otherMaritalInfo?.mSts)
    );
    const isSingleAfter = fedSpouses.some(
      (otherMaritalInfo) =>
        isSameDayOrAfter(otherMaritalInfo, currentMaritalInfo) &&
        isSingleNotUndefined(otherMaritalInfo?.mSts)
    );
    return (
      isCommonLaw(currentMaritalInfo?.mSts) &&
      !hasDivorcedOrWidowedAfter &&
      !hasMarriedAfter &&
      !isSingleAfter
    );
  });
  if (commonLawSpouse) {
    return commonLawSpouse;
  }

  // find the first one (in the list ordered by date desc) where status is married
  // and does not have a widowed/divorced status after with the same person
  const marriedSpouse = fedSpouses.find((currentMaritalInfo) => {
    /** has divorced or widowed the same person after */
    const hasDivorcedOrWidowedAfter = fedSpouses.some(
      (otherMaritalInfo) =>
        isSameDayOrAfter(otherMaritalInfo, currentMaritalInfo) &&
        isSamePerson(currentMaritalInfo, otherMaritalInfo) &&
        (isWidowed(otherMaritalInfo?.mSts) ||
          isDivorced(otherMaritalInfo?.mSts))
    );
    const isSingleAfter = fedSpouses.some(
      (otherMaritalInfo) =>
        isSameDayOrAfter(otherMaritalInfo, currentMaritalInfo) &&
        isSingleNotUndefined(otherMaritalInfo?.mSts)
    );
    return (
      isMarried(currentMaritalInfo?.mSts) &&
      !hasDivorcedOrWidowedAfter &&
      !isSingleAfter
    );
  });
  if (marriedSpouse) {
    return marriedSpouse;
  }

  return undefined;
};

/**
 * Find legal spouse in Quebec (Supplemental Pension Plans Act) jurisdiction
 *
 * Your spouse is the person who, at the date a determination of spousal status is required:
 * a) is married to you; or
 * b) if you are not married to anyone, a person of the opposite sex or same sex who has been living in a conjugal relationship with you for a period of not less than three years, or for a period of not less than one year if:
 * (i)  at least one child is born, or to be born, of your union;
 * (ii)  you have jointly adopted at least one child while living together in a conjugal relationship; or
 * (iii)  one of you has adopted at least one child of the other while living together in a conjugal relationship.
 */
const findSpouseQc = (list) => {
  // schema: https://app.diagrams.net/#G152WFeUFv0Ya3pF7OEuzwdkrujteurNcX#%7B%22pageId%22%3A%223h1_zvnjCFUreUOw-FLl%22%7D

  /** List of spouses in quebec or both jurisdictions, ordered by date desc (oldest first) */
  const qcSpouses = sortByDateDesc(list).filter(
    (x) =>
      x.jurisdiction === jurisdiction_que ||
      x.jurisdiction === jurisdiction_both
  );

  const hasNoSpouse = qcSpouses.length === 0;
  if (hasNoSpouse) {
    return undefined;
  }

  // find if the first one (in the list ordered by date desc) is where status is single
  const lastSingle = isSingleNotUndefined(qcSpouses[qcSpouses.length - 1]?.mSts)
    ? qcSpouses[qcSpouses.length - 1]
    : undefined;
  if (lastSingle) {
    return lastSingle;
  }

  // find the first one (in the list ordered by date desc) where status is married
  // and does not have a widowed/divorced status after with the same person
  const marriedSpouse = qcSpouses.find((currentMaritalInfo) => {
    /** has divorced or widowed the same person after */
    const hasDivorcedOrWidowedAfter = qcSpouses.some(
      (otherMaritalInfo) =>
        isSameDayOrAfter(otherMaritalInfo, currentMaritalInfo) &&
        isSamePerson(currentMaritalInfo, otherMaritalInfo) &&
        (isWidowed(otherMaritalInfo?.mSts) ||
          isDivorced(otherMaritalInfo?.mSts))
    );
    const isSingleAfter = qcSpouses.some(
      (otherMaritalInfo) =>
        isSameDayOrAfter(otherMaritalInfo, currentMaritalInfo) &&
        isSingleNotUndefined(otherMaritalInfo?.mSts)
    );
    return (
      isMarried(currentMaritalInfo?.mSts) &&
      !hasDivorcedOrWidowedAfter &&
      !isSingleAfter
    );
  });
  if (marriedSpouse) {
    return marriedSpouse;
  }

  // find the first one (in the list ordered by date desc) where status is common law
  // and does not have a widowed/divorced status after with the same person
  const commonLawSpouse = qcSpouses.find((currentMaritalInfo) => {
    /** has divorced or widowed the same person after */
    const hasDivorcedOrWidowedAfter = qcSpouses.some(
      (otherMaritalInfo) =>
        isSameDayOrAfter(otherMaritalInfo, currentMaritalInfo) &&
        isSamePerson(currentMaritalInfo, otherMaritalInfo) &&
        (isWidowed(otherMaritalInfo?.mSts) ||
          isDivorced(otherMaritalInfo?.mSts))
    );
    const isSingleAfter = qcSpouses.some(
      (otherMaritalInfo) =>
        isSameDayOrAfter(otherMaritalInfo, currentMaritalInfo) &&
        isSingleNotUndefined(otherMaritalInfo?.mSts)
    );
    return (
      isCommonLaw(currentMaritalInfo?.mSts) &&
      !hasDivorcedOrWidowedAfter &&
      !isSingleAfter
    );
  });
  if (commonLawSpouse) {
    return commonLawSpouse;
  }

  return undefined;
};

const SpouseHistoryUtilModule = {
  jurisdiction_fed,
  jurisdiction_que,
  jurisdiction_both,
  findSpouseFed,
  findSpouseQc,
  isSingle,
  isSingleNotUndefined,
  isMarried,
  isCommonLaw,
  isSeparated,
  isDivorced,
  isWidowed,
  isSamePerson,
};

export default SpouseHistoryUtilModule;
