import { Service } from '../../framework';
import { Period } from '../../framework/utils';
import { getSafe, round, moment } from '../../framework/utils/helper';
import { Employment, RemittanceDetails } from '../../entities';
import { EmploymentBusiness, RemittanceDetailBusiness } from '../../business';
import { EmploymentService } from '..';
import { RemittanceDetailService, AdjustmentService } from '.';
import { Excel } from '../../framework/utils';
import { Table } from '../../framework/controls';
import YearEndMessageEvent from "../../entities/yearEnd/YearEndMessageEvent";
import { Report } from '../../entities/yearEnd/Report';
import { RefHistorical } from '../../framework/infra';
import RefDescription from '../../framework/infra/model/RefDescription';
import YearEndEmploymentSummary from '../../entities/yearEnd/YearEndEmploymentSummary';
import Validations from '../../entities/yearEnd/Validation';
import { contributionErrorInterval, earningErrorInterval, hourErrorInterval } from '../../entities/yearEnd/YearEndConfig';

const CS_VARIATION = 6

class YearEndService extends Service {

    constructor() {
        super(null, 'YearEnd', 'report')
    }

    clearCache () {
        RemittanceDetailService.invalidateCache();
        AdjustmentService.invalidateCache();
        EmploymentService.invalidateCache();
    }

    //generates report data for all employments in year or a specific employment
    async getData({employer, employment, year, allowNoYearEnds = false, useDefaultSpouse = true}) {

        const report = { 
            details: [],
            processed: 0
        }
        
        //Get all employments
        let prefix = '['+ (employer?.code ?? employment?.employer?.code)+' REPORT]' 
        console.log(prefix, 'Getting data from server...');
        const data = await this.getYearEndData({employment, employer: employer ?? employment?.employer, years: [(Number(year)-1).toString(), year ]});
        const adjs = await AdjustmentService.getAdjustmentsForEmployer(employer?.id ?? employment?.employer?.id);
        console.log(prefix, 'Grouping details by employments...');
        const detailsPerEmployment = this.getDetailsPerEmployment(data);
        console.log(detailsPerEmployment);
        let processed = 0;
        let total = Object.entries(detailsPerEmployment).length;

        for (const [key, details] of Object.entries(detailsPerEmployment)) {
            const row = await this.proccessEmployment(details, adjs, year, allowNoYearEnds, useDefaultSpouse);
            if (row?.key) report[row.key].push(row.value);
            processed++;
            console.log(prefix, 'Processing: ' + processed + ' of ' + total);
            report.processed = processed;
        }

        report.details.sort((a, b) => a.name > b.name ? 1 : -1);
        return report;
    }

    async getYearEndData({employer, employment, years = []}) {
        const response = await RemittanceDetailService.getYearEndData(
            employer?.id, 
            employment?.keyValue, 
            years
        );

        return response;
    }

    getDetailsPerEmployment(data) {
        return data.all.reduce((all, detail) => {
            let group = all[detail.keysValues.participation] ?? new RemittanceDetails();
            group.push(detail);
            all[detail.keysValues.participation] = group;
            return all;
        }, {})
    }
    //generates report data for single employment in year
    async proccessEmployment(details, adjustments, targetYear, allowNoYearEnds = false, useDefaultSpouse = true) {
        try {

            const reportRow = new YearEndEmploymentSummary();

            //go trough ajdustments list to see if a detail associated to the adjustment is missing in the details list
            //if it is, add it to the details list so that it's properly mapped with adjustments are mapped to details
            let employmentAdjustments = adjustments.filter(adjustment => adjustment.employment.keyValue === details.all[0].employment.keyValue);
            employmentAdjustments.forEach(employmentAdjustment => {
                if (!details.all.find(detail => detail.period.isSame(employmentAdjustment.period) && employmentAdjustment.period.year === targetYear)) {
                    details.push(employmentAdjustment.remDetail);
                }
            });

            details.forEach(detail => detail.initDetailAdjustment(adjustments));
            details.setYtps();

            let allMonthlyRemittances = details.filter(x=> !x.period.yearEnd && x.period.year === targetYear).sort((rem1,rem2) => rem1.period.moment.valueOf()-rem2.period.moment.valueOf());
            let lastMonthRemittanceDetail = allMonthlyRemittances[allMonthlyRemittances.length-1];
            let yearEndRemittanceDetail = details.find(remittance => remittance?.period?.value === targetYear);
            let lastEndRemittanceDetails = details.find(remittance => remittance?.period?.value === (Number(targetYear)-1).toString());

            //replace with last month since there is no yearend. (In case termination report)
            if (!yearEndRemittanceDetail && allowNoYearEnds)  yearEndRemittanceDetail = lastMonthRemittanceDetail; 
            if (!yearEndRemittanceDetail || !lastMonthRemittanceDetail) return;

            const employment = yearEndRemittanceDetail.employment;
            const row = { key: allowNoYearEnds ? 'details' : null , value: reportRow };

            reportRow.assignEmployment(employment, useDefaultSpouse);
            if (yearEndRemittanceDetail) reportRow.assignEnd(yearEndRemittanceDetail);
            if (lastEndRemittanceDetails) reportRow.assignLastEnd(lastEndRemittanceDetails);

            if (yearEndRemittanceDetail.isRelevant()) {
                row.key = 'details';
                const validationResults = await this.validate(yearEndRemittanceDetail, lastMonthRemittanceDetail, lastEndRemittanceDetails, employment, details, targetYear);
                reportRow.assignValidationResults(validationResults);
            }
            return row;
        } catch (e) {
            console.debug(e);
        }
        
    }

    async validate(lastDetail, lastDetailMonth, lastYearDetail, employment, allDetails, targetYear) {
        let validation = new Validations();
        const statusesForYear = lastDetail.employmentEventStatuses.map(event => event.status)
        const lastPeriodThisYear = lastDetailMonth.period;
        const lastStatus = lastDetail.empStatusEvent.status;
        const lastStatusPrevYear = lastYearDetail?.empStatusEvent.status;
        const isEligibleDuringYear = employment.participation.eventStatuses.find(event => event.status.isEligible())
        const fullYearRem = allDetails.find(remittance => remittance?.period?.value === targetYear)
        const joinMoment = moment(employment.participation.joinDt);
        const firstDayOfYear = moment(targetYear).startOf('year');

        const periodStart = lastDetail.period.timestampAtPeriodStart;
        const periodEnd   = lastDetail.period.timestampAtPeriodEnd;
        const guessedEvents = new RefHistorical([ ...employment.events.all, ...employment.participation.events.all]).getAllDuring( periodStart, periodEnd ).filter(e => e.guessed);
        const joinEvent = lastDetail.employment.participation.events.find(e => e.config.isEnrollEvent && !e.config.hideForJanOneWarning)

        //Don't show warning if a member is not relevant, but has adjustment in this year targeting prior year
        if(employment.isRelevantForPeriod(lastDetail.period) && lastDetail.hasNoFinancialData() && lastDetail.ytdPriorAdjustment !== 0){
            validation.result.push(this.validationMessages({key: 'ok'}));
            return validation;
        }

        //if not validated
        if (!fullYearRem?.yeValidated) { 
            //Monthly Earnings validations
            var monthsAct = '', monthsLoa = '', monthsSlf = ''

            //for missing remittances details
            const remDetailsForPeriod =  allDetails.filter(det => det.period.isSameYear(lastPeriodThisYear) && !det.period.yearEnd);
            if(remDetailsForPeriod.length !== 12){
                const firstOfMonth = Period.create(lastPeriodThisYear.year + '01')
                Period.getPeriods(firstOfMonth, lastPeriodThisYear, false).forEach(period => {
                    if(!remDetailsForPeriod.find(detail => detail.period.isSame(period))){
                        const eventStatusPeriod = employment.eventStatuses.getDuring(period.timestampAtPeriodStart, period.timestampAtPeriodEnd);
                        const lastEventPeriod = eventStatusPeriod[eventStatusPeriod.length - 1];
                        const eventBeforeMidMonth = moment(lastEventPeriod?.effDt).valueOf() < moment(period.dec().moment.format('YYYY-MM-15')).valueOf();
                        if (eventStatusPeriod.length === 1 && employment.isActiveDuringPeriod(period, lastEventPeriod) && eventBeforeMidMonth) monthsAct += ', ' + period.moment.format('MMM');
                    }
                })
            }
            //foreach rem details by monthly period 01->12 excluding the yearly period 
            remDetailsForPeriod.forEach(det => {
                const periodEventStatuses = det.employmentEventStatuses;
                const lastEventPeriod = periodEventStatuses[periodEventStatuses.length - 1];
                if (periodEventStatuses.length === 1) { //same status all month
                    const eventBeforeMidMonth = moment(lastEventPeriod.effDt).valueOf() < moment(det.period.dec().moment.format('YYYY-MM-15')).valueOf();
                    if (employment.isActiveDuringPeriod(det.period, lastEventPeriod) && !det.earnings.deductable && eventBeforeMidMonth) { monthsAct += ', ' + det.period.moment.format('MMM')}
                    if (det.earnings.deductable && (lastEventPeriod.status.isOnLeave() || lastEventPeriod.status.isLayOff() || lastEventPeriod.status.isTransfer()) && eventBeforeMidMonth) monthsLoa += ', ' + det.period.moment.format('MMM')
                }
            })

            employment.events.sortEvents();
            
            const dateOfLeave = employment.events.checkAcceptedContributionsForSelfLeaves(lastDetail.period)
            if (dateOfLeave) { 
                monthsSlf += ', ' + dateOfLeave;
            }


            // Validate Status (group: sts)
            if (lastStatus.isActive() && lastDetail.empStatusEvent.ets <= lastDetail.period.timestampAtPeriodStart && !lastDetail.ytdEarnings.deductable) validation.sts.push(this.validationMessages(('stsActNoEarnings'))); // 1
            if (statusesForYear.length === 1 && employment.onContributingLeave() && lastDetail.ytdEarnings.deductable) validation.sts.push(this.validationMessages('stsLeaveAllYearAndEarnings')); // 2
            if (employment.participation.joinDt < employment.hiredDate && !employment.participation.isMER) validation.sts.push(this.validationMessages('stdJoinDtAfterHireDt')); // 3
            if (lastStatusPrevYear && lastStatusPrevYear.isPermanentTerminated() && !statusesForYear.find(status => status.isActive() && lastDetail.ytdEarnings.deductable)) {
                if (lastDetail.pensionAdjustment > 0) validation.sts.push(this.validationMessages('stdTermitatedPrevYearHasPA')); // 5
                else validation.sts.push(this.validationMessages('stdTermitatedPrevYear')); // 4
            }
            if (isEligibleDuringYear) validation = this.verifyBME(lastDetail, validation); // 6
            if (joinMoment.isAfter(firstDayOfYear, 'day') && joinMoment.isSame(firstDayOfYear, 'year') && joinEvent) validation.sts.push(this.validationMessages('janOne')); // 7
            if (!Employment.definitions.employmentType.options.map(o => o.key).includes(employment.employmentType)) validation.sts.push(this.validationMessages('missingEmploymentType')); // 8
            if (employment.isCasual(periodEnd)) validation.sts.push(this.validationMessages('casualType')); // 9
            if (guessedEvents.length > 0) guessedEvents.forEach(event => {
                validation.sts.push(this.validationMessages('guessedDate', [{key: 'date', value: event.effDt}])); // 10
            });
            if (statusesForYear.find(status => status.isMaternity() && !lastDetail.ytdEarnings.mat)) validation.sts.push(this.validationMessages('stdMatAndNoMatContribs'));
            if (personalDataProperties.find(prop => !getSafe(employment, prop))) validation.persInfo.push(this.validationMessages('missingPersonalInformation')); //Validate Personal Data

            // Monthly Earnings Validation (group: monthlyEarnings)
            if (monthsAct || monthsLoa || monthsSlf) {
                validation.monthlyEarnings.push(this.validationMessages('monthlyEarnings', [
                    {key: 'monthsAct', value: monthsAct.replace(/^, /, '').replace(/,([^,]*)$/, ' and$1')}, // 11
                    {key: 'monthsLoa', value: monthsLoa.replace(/^, /, '').replace(/,([^,]*)$/, ' and$1')}, // 12
                    {key: 'monthsSlf', value: monthsSlf.replace(/^, /, '').replace(/,([^,]*)$/, ' and$1')}, // 13
                ]))
            }

            RemittanceDetailBusiness.validate(lastDetail);
            if(lastDetail.messages.length > 0){
                lastDetail.messages._list.filter(message => message.isError()).forEach(message => {
                    if(validation.sts){ 
                        const newText = validation['sts']._msgDesc.text.concat('\n• ', message.desc);
                        validation.sts._msgDesc = new RefDescription({...validation.sts._msgDesc, text: newText});
                    }
                    else validation.sts = message;
                });
            }

            if (isEligibleDuringYear) {

                // Credit Service Validation (group: creSrv)
                const creSrvValidations = {
                    csNotFullYear: lastDetail.ytdCreditedService >= 12 && (employment.participation.joinDt > String(targetYear) + '-01-01' || (employment.participation.joinDt < String(targetYear) + '-12-31' && !lastDetail.ppStatus.isEligible())), // 14
                    csFullYear: false, // 15
                    regExceedWS: round(lastDetail.ytdEarnings.regularHours, 0) > round(lastDetail.workSchedule.yearlySchedule, 0), // 16
                    csVariationLess:  false, // 17
                    csVariationGreater: false, // 18
                    workSchMissing: !employment.workSch.key, // 19
                }
                const yearWorkSch = employment.workSchHistory.getDuring(lastDetail.period.timestampAtPeriodStart, lastDetail.period.timestampAtPeriodEnd);
                const terminatedDuringYear = employment.participation.eventStatuses.getAllDuring(lastDetail.period.timestampAtPeriodStart, lastDetail.period.timestampAtPeriodEnd)?.find(ev => ev.status.isTerminated())
                if(yearWorkSch.length > 1 && yearWorkSch[0].value.weeklySchedule > yearWorkSch[yearWorkSch.length - 1].value.weeklySchedule && creSrvValidations.regExceedWS && !terminatedDuringYear){
                    const maxWorkSch = Math.max(...yearWorkSch.map(sch => sch.value.weeklySchedule));
                    creSrvValidations.regExceedWS = round(lastDetail.ytdEarnings.regularHours, 0) > (round(lastDetail.workSchedule.yearlySchedule, 0) + (maxWorkSch * 2));
                }

                if (!employment.isPartTime(lastDetail.period.timestampAtPeriodEnd)) {
                    const cContributingDaysInYear = employment.getContributingDaysInYear(lastDetail.period);
                    const csActiveDays = cContributingDaysInYear / 365 * 12
                    const variationReg = (lastDetail.ytdCreditedService && !Number.isNaN(lastDetail.ytdCreditedService)) ? (csActiveDays - lastDetail.ytdCreditedService) / lastDetail.ytdCreditedService : NaN; // avoid dividing by 0 because it returns Infinity

                    const activeEvBeforeJoinDt = moment(lastDetail.empStatusEvent.ets).isSameOrBefore(moment(employment.participation.joinDt))
                    const activeDt = activeEvBeforeJoinDt ? moment(employment.participation.joinDt) : lastDetail.empStatusEvent.ets;
                    const isLastAcitveEvAllYear = moment(activeDt).isSameOrBefore(moment(String(targetYear) + '-01-01'))

                    if(lastDetail.empStatusEvent.status.isActive() && isLastAcitveEvAllYear && round(lastDetail.ytdCreditedService) < 12){
                        creSrvValidations.csFullYear = true;
                    }

                    const activeDaysMinusCreditedService = (typeof csActiveDays === 'number' && !Number.isNaN(csActiveDays) && typeof lastDetail.ytdCreditedService === 'number' && !Number.isNaN(lastDetail.ytdCreditedService)) ? csActiveDays -  lastDetail.ytdCreditedService : NaN;
                    /** Is warning `csVariationGreater`: `(16) Service based on pensionable hours is over 6% greater than Service based on "Active Days"` */
                    const is16Warning = typeof activeDaysMinusCreditedService === 'number' && !Number.isNaN(activeDaysMinusCreditedService) && activeDaysMinusCreditedService <= 0;
                    /** Is warning `csVariationLess`: `(17) Service based on "Active Days" is over 6% greater than Service based on pensionable hours` */
                    const is17Warning = typeof activeDaysMinusCreditedService === 'number' && !Number.isNaN(activeDaysMinusCreditedService) && activeDaysMinusCreditedService > 0;

                    const variationRegGreaterThanCS_VARIATION = Math.abs(variationReg) > (Math.abs(CS_VARIATION) / 100);
                    if (variationRegGreaterThanCS_VARIATION){
                        const variationDays = (csActiveDays/12*365) - (lastDetail.ytdCreditedService/12*365)
                        if(Math.abs(variationDays) > 14) {
                            if(is17Warning) {
                                creSrvValidations.csVariationLess = true;
                            }
                        }
                    }
                    if (variationRegGreaterThanCS_VARIATION) {
                        const variation = !lastDetail.workSchedule.yearlySchedule || Number.isNaN(lastDetail.workSchedule.yearlySchedule) ? NaN : 
                            (lastDetail.ytdEarnings.regularHours - lastDetail.ytdEarnings.overtimeHours) /  lastDetail.workSchedule.yearlySchedule;
                        const variationGreaterThanCS_VARIATION = Math.abs(variation) > (Math.abs(CS_VARIATION) / 100);

                        if (variationGreaterThanCS_VARIATION){
                            const variationDays = ((lastDetail.ytdCreditedService) / 12 * 365) - (csActiveDays / 12 * 365)
                            if(Math.abs(variationDays) > 14) {
                                if(is16Warning) {
                                    creSrvValidations.csVariationGreater = true;
                                }
                            }
                        }
                    }
                }

                if (Object.values(creSrvValidations).find(val => val)) {
                    validation.creSrv.push(this.validationMessages('creSrv', [
                        {key: 'csNotFullYear', value: creSrvValidations.csNotFullYear},
                        {key: 'csFullYear', value: creSrvValidations.csFullYear},
                        {key: 'csVariationGreater', value: creSrvValidations.csVariationGreater},
                        {key: 'csVariationLess', value: creSrvValidations.csVariationLess},
                        {key: 'regExceedWS', value: creSrvValidations.regExceedWS},
                        {key: 'workSchMissing', value: creSrvValidations.workSchMissing},
                    ]));
                }
            }
        }

        //Validate the financial data if it contribued during the year
        if (isEligibleDuringYear) {
            //Required Contribution Validation
            var reCalc = round(RemittanceDetailBusiness.calculateDeduction(lastDetail.ytdEarnings.pensionable, 0, 0, employment.isCQ === 'y', lastDetail.rates))
            if (Math.abs(lastDetail.ytdContributions.excludesVoluntary - reCalc) > 1) validation.reqContrib.push(this.validationMessages('reqValidation', [{key: 'value', value: lastDetail.ytdContributions.excludesVoluntary}, {key: 'to', value: reCalc}]))
           
            // Deemed started in prior year
            validation = this.verifyDeemedEffectiveDateInPriorYear(employment, targetYear, validation);

            // Deemed Contribution Validation (group: deeContrib)
            validation = await this.verifyExpectedDeemedEarningsAndHours(
                statusesForYear,
                allDetails,
                validation, employment,
                lastPeriodThisYear,
            );
            reCalc = round(RemittanceDetailBusiness.calculateDeduction(lastDetail.ytdEarnings.deemed, 0, 0, employment.isCQ === 'y', lastDetail.rates))
            //Maximum Contribution Validation
            if (lastDetail.ytdContributions.total >= lastDetail.rates.maxContributions && (lastDetail.ytdContributions.vol >= (employment.isCQ === 'y' ? lastDetail.rates.maxVoluntaryCQPP : lastDetail.rates.maxVoluntaryNonCQPP))) validation.maxContrib.push(this.validationMessages('maxBoth'))
            else if (lastDetail.ytdContributions.total >= lastDetail.rates.maxContributions) validation.maxContrib.push(this.validationMessages('maxCon'))
            else if (lastDetail.ytdContributions.vol >= (employment.isCQ === 'y' ? lastDetail.rates.maxVoluntaryCQPP : lastDetail.rates.maxVoluntaryNonCQPP)) validation.maxContrib.push(this.validationMessages('maxVol'))
            
            //Required Contribution Validation
            if (!lastDetail.financialCmt) {
                const lastYearRegAE = lastYearDetail?.ytdEarnings.regular * lastYearDetail?.workSchedule.yearlySchedule / lastYearDetail?.ytdEarnings.regularHours
                if (lastYearRegAE && !isNaN(lastYearRegAE)) {
                    const currYearRegAE = lastDetail.ytdEarnings.regular * lastDetail.workSchedule.yearlySchedule / lastDetail.ytdEarnings.regularHours
                    reCalc = (currYearRegAE - lastYearRegAE) / lastYearRegAE
                    if (reCalc > 0.15) validation.annErn.push(this.validationMessages('highEarn', {value: reCalc}))
                    else if (reCalc < -0.05) validation.annErn.push(this.validationMessages('lowEarn', {value: reCalc}))
                }
            }
        }
        
        //validate if all ok
        const validationNames = ['persInfo' , 'sts', 'monthlyEarnings', 'reqContrib', 'deeContrib', 'creSrv', 'annErn', 'result']
        
        if (!validationNames.find(valName => {
            return validation[valName] && validation[valName].hasError()
        })) validation.result.push(this.validationMessages('ok'))
        else validation.result.push(this.validationMessages('incomplete'))

        return validation;
    }

    getYEMercerReportData(employers, year) {
        const report = employers.reduce((report, er) => {
            const rep = this.getData({employer: er, year})
            Object.getOwnPropertyNames(report).forEach(propName => report[propName] = report[propName].concat(rep[propName]))
            return report
        }, {details: []})
        return report
    }

    validationMessages(key, parameters) { 
        return new YearEndMessageEvent({ code: key, params: parameters });
    }

    verifyBME(detail, validation) {
        const hasSelfAcceptedEvent = detail.employment.events.getDuring( detail.period.timestampAtPeriodStart, detail.period.timestampAtPeriodEnd ).find((event) => event.selfContribAccepted);
        const hasEligibleSelfLeave = detail.employmentEventStatuses.find(event => event.status.eligibleForSelfContribution());
        
        const missingBME = detail.employmentEventStatuses.filter((event) => event.status.isDeemedStatus()).reduce((showError, event) => {
            const baseEarningsInPeriod = detail.employment.baseEarningsHistory.getAllDuringWithEndTs(detail.period.timestampAtPeriodStart, detail.period.timestampAtPeriodEnd );
            const baseEarningsForPeriod = baseEarningsInPeriod?.[baseEarningsInPeriod.length - 1]?.value ?? detail.employment.baseEarningsHistory.getAt(event.ets)?.value;
            const baseEarnings = baseEarningsForPeriod !== 0 ? baseEarningsForPeriod : detail.employment.baseEarnings;
            return ( showError || !baseEarnings || baseEarnings === 0 );
        }, false);

        if ((!hasEligibleSelfLeave || (hasEligibleSelfLeave && hasSelfAcceptedEvent)) && missingBME) {
            validation.sts.push(this.validationMessages("missingBME")); // 6
        }
        return validation;
    }

    verifyDeemedEffectiveDateInPriorYear(employment, targetYear, validation) {
        const eventAtYearStart = employment.events.getAt(moment(targetYear).valueOf());
        const isEffDateBeforeYearStart = eventAtYearStart?.effMoment.isBefore(moment(targetYear));
        const isDeemedAtYearStart = eventAtYearStart.status.isDeemedStatus();

        if (isEffDateBeforeYearStart && isDeemedAtYearStart) {
            validation.reqContrib.push(this.validationMessages('deemedPrior'));
        }

        return validation;
    }

    async verifyExpectedDeemedEarningsAndHours(statusesForYear, allDetails, validation, employment, lastPeriodThisYear) {
        let expectedEarn = 0, expectedContribs = 0, expectedHours = 0;
        const yearContainsDeemedStatus = statusesForYear.find((status) =>
            status.isDeemedStatus()
        );
        if (yearContainsDeemedStatus) {

            const { actual, expected, difference, calculatedDetails } = await EmploymentBusiness.checkDeemedAmounts({
                employment, 
                startPeriod: Period.create(lastPeriodThisYear.year + "01"),
                endPeriod: Period.create(lastPeriodThisYear.year + "12"),
                dbDetails: allDetails,
            });

            if(Math.abs(difference.earnings.deemed) > earningErrorInterval) expectedEarn = round(expected.total.earnings);
            if(Math.abs(difference.contributions.deemed) > contributionErrorInterval) expectedContribs = round(expected.total.contributions);
            if(Math.abs(difference.earnings.deemedHours) > hourErrorInterval) expectedHours = round(expected.total.hours);
            
            if(expectedEarn || expectedContribs || expectedHours) {
                validation["deeContrib"].push(this.validationMessages("deemedEarnHoursValidation", [
                    { key: "expectedEarn", value: expectedEarn },
                    { key: "ytdEarn", value: actual.earnings },
                    { key: "earnAdj", value: round(difference.earnings.deemed)},
                    { key: "expectedContribs", value: expectedContribs },
                    { key: "ytdContribs", value: actual.contributions },
                    { key: "contribsAdj", value: round(difference.contributions.deemed)},
                    { key: "expectedHours", value: expectedHours },
                    { key: "ytdHours", value: actual.hours },
                    { key: "hoursAdj", value: round(difference.earnings.deemedHours)},
                ]))
            }
        }
        return validation;
    }
    
    getHeaderGroups(type, employer) {
        //no employer means we are reporting on all employers, add extra column
        var groups = employer ? [] : ['employer'] 
        switch (type) {
            case displayType.FULL:
            case displayType.TERM:
                return new Excel.Headers(Report,  [...groups, ...fullHeaderGroups]);
            case displayType.SIMPLE: 
            default:
                return new Table.Headers(Report,  [...groups, ...simpleHeaderGroups]);
        }
    }

    initHeader(groups, displayType, earningsTypesByCategory, employer){
        let groupHeaders = this.getHeaderGroups(groups, employer)
        return this.getHeader(groups, displayType, earningsTypesByCategory, groupHeaders)
    }

    getHeader(groups, displayType, earningsTypesByCategory, groupHeaders) {
        groupHeaders.list.forEach(groupHeader => {
            var name = groupHeader._def?.name || groupHeader.name
            switch (name) {
                case 'OTE':
                    groupHeader.title = earningsTypesByCategory[name].text
                    groupHeader.headers = new displayType.Headers(YearEndEmploymentSummary, earningsTypesByCategory[name].earningTypes.map(type => 'ytdEarnings.' + type.code + '.amount'))
                    earningsTypesByCategory[name].earningTypes.map(type => {
                        groupHeader.headers['ytdEarnings.' + type.code + '.amount'].title = (type.alias || type.label) + ' amount'
                    })
                    break;
                case 'REG':
                case 'OVR':
                    groupHeader.title = earningsTypesByCategory[name].text
                    groupHeader.headers = new displayType.Headers(YearEndEmploymentSummary, earningsTypesByCategory[name].earningTypes.reduce((catHeaders, type) => {
                        catHeaders.push('ytdEarnings.' + type.code + '.amount')
                        catHeaders.push('ytdEarnings.' + type.code + '.hours')
                        return catHeaders
                    }, []))
                    earningsTypesByCategory[name].earningTypes.forEach(type => {
                        groupHeader.headers['ytdEarnings.' + type.code + '.amount'].title = (type.alias || type.label) + ' amount'
                        groupHeader.headers['ytdEarnings.' + type.code + '.hours'].title = (type.alias || type.label) + ' hours'
                    })
                    break;
                default: 
                    groupHeader.headers = new displayType.Headers(YearEndEmploymentSummary, headers(groups)[name])
            }
            groupHeader.name = ''
        })

        return groupHeaders
    }

    get displayTypes() { return displayType }
}


//properties requiring personal data validation
const personalDataProperties = [
    'person.lastName', 
    'person.firstName', 
    'person.dob', 
    'person.gender', 
    'person.lng', 
    'isN', 
    'isCQ', 
    'isTP', 
    'hiredDate', 
    'participation.joinDt'
]
const simpleHeaderGroups = [
    'employment', 
    'details', 
    'pensionAdjustment'
]
const fullHeaderGroups = [
    'employmentFull',
    'details', 
    'pensionableEarnings', 
    'contributions', 
    'creditedService', 
    'pensionAdjustment', 
    'lastYearDetails', 
    'validation', 
    'adjustments', 
    'REG', 
    'OVR', 
    'OTE'
]

const displayType = {
    SIMPLE: 'SIMPLE',//simpleHeaderGroups,
    FULL: 'FULL', //fullHeaderGroups
    TERM: 'TERM', //fullHeaderGroups 
    MERCER: 'MERCER', //mercerReportGroups
}

const headers = (type) => { return {
    'employer': [
        'code'
    ],
    'employmentBrief':[
        'sin', 
        'lastName', 
        'firstName', 
    ],
    "employment" : [
        'sin',
        'name', 
        'isCQ', 
        'hiredDate', 
        'joinDt', 
        'lastSchedule.yearlySchedule',
        'schedule.yearlySchedule',
        'lastYtdCreditedService', 
        'ytdCreditedService'
    ],
    "employmentFull" : [
        'sin', 
        ...(type === displayType.TERM )? [
            'employment.noEmp',
            'mercerKey',
        ] : [],
        'lastName', 
        ...(type === displayType.TERM ) ? [
            "middleName",
        ] : [],
        'firstName', 
        'dob', 
        ...(type === displayType.TERM )? [
             'address', 
             'email', 
             'phone'
        ] : [], 
        'gender', 
        'lng', 
        'isN', 
        'isCQ', 
        'isTP', 
        'hiredDate', 
        'joinDt',
        ...(type === displayType.TERM )? [
            'maritalStatus',
            'spouseName',
            'spouseDob',
            'spouseSin',
            'beneficiaries',
        ]: []
    ],
    "details": [
        "employmentPpStatusDesc",
        "employmentStatusEffDate",
        ...(type === displayType.FULL )? [
            "ppStatusEventDesc",
            "ppStatusEventEffectiveDate",
        ] : [], 
        "employmentType",
    ],
    "detailsFull":[
        'workSchDesc',
        'ytdCreditedService',
    ],
    "pensionableEarnings": [
        "ytdEarnings.pensionable",
        "ytdEarnings.deductable",
        "ytdEarnings.regular",
        "ytdEarnings.overtime",
        "ytdEarnings.oneTime",
        "ytdEarnings.deemed",
    ],
    "contributions": [
        "ytdContributions.excludesVoluntary",
        "ytdContributions.reg",
        "ytdContributions.mat",
        "ytdContributions.ltd",
        "ytdContributions.slf",
        "ytdContributions.vol",
    ],
    "creditedService": [
        "ytdCreditedService",
        "schedule.yearlySchedule",
        "ytdEarnings.regularHours",
        "ytdEarnings.overtimeHours",
        ...(type !== displayType.MERCER )?[
            "ytdEarnings.deemedHours"
        ]:[],
        'yearActiveDays'
    ],
    "pensionAdjustment": [
        "pensionAdjustment"
    ],
    "lastYearDetails": [
        "lastAnnualizedEarningsRegular",
        "annualizedEarningsRegular",
    ],
    "adjustments": [
        "ytdPriorAdjustment",
    ],
    "validation": [
        "validation.persInfo",
        "validation.sts",
        "validation.monthlyEarnings",
        "validation.reqContrib",
        "validation.deeContrib",
        "validation.maxContrib",
        "validation.creSrv",
        "validation.annErn",
        "validation.result",
        ...(type === displayType.TERM ? [
            "reviewedCmt",
            "financialCmt",
            "cmt",
            "reasonDaysDiff",
            "confStatus",
            "finalPayOut",
        ] : (type === displayType.MERCER ? [
            "reviewedCmt",
            "financialCmt",
            "reasonDaysDiff",
            "confStatus",
            "finalPayOut",
        ] : [])), 
    ],
    "personalValidation":[
        "validation.persInfo"
    ],
    "statusValidation":[
        'validation.sts',
        'skip-1',//blank to offset
        'validation.monthlyEarnings', 
        'skip-2', //blank to offset
        'validation.creSrv', 
        'skip-3',//blank to offset
    ],
    "financialValidation": [
        'validation.annErn',
        'financialCmt',
        "reasonDaysDiff",
        "confStatus",
        "finalPayOut",
    ],
    'mercerIntro':[
        'code',
        'mercerDiv',
        'mercerKey',
    ],
    'comment': [
        'employerCmt'
    ],
    'creditedServiceHeader':[
        'employmentType',
        'employmentPpStatusDesc',
        'lastWorkDay',
        'ytdCreditedService',
        'skip-1', //blank to offset
        'skip-2', //blank to offset
        'skip-3', //blank to offset
        'reasonDaysDiff',
        'confStatus',
        'finalPayOut',
        'address',
        'email', 
        'phone',
        'skip-4', //blank to offset
        'maritalStatus',
        'spouseName',
        'spouseDob',
    ],
}}

const instance = new YearEndService()
export default instance
