import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Spouse } from '../../entities/person'
import SpouseHistory from '../../entities/person/SpouseHistory'
import { Component, EInput, Question } from '../../framework/components'
import { Row, FieldSet, Title } from '../../framework/containers'
import uuid from 'uuid/v4'


import PersonSummary from '../person/PersonSummary'
import { SpouseService } from '../../services'
import { Check } from '../../framework/inputs'
import UnknownPersonForm from '../person/UnknownPersonForm'
import { Select } from '../../framework/controls'

const MaritalStatusChangeWarnings = {
	MarriedToSingle: 'You are changing the marital information from Married to Single. Have you received the required legal documents (i.e. divorce certificate)?',
	MarriedToDivorced: 'You are changing the marital information from Married to Divorced. Have you received the required legal documents (i.e. divorce certificate)?',
	MarriedToCommonLaw: 'You are changing the marital information from Married to Common Law. Have you checked if the previous spouse still married to the member?',
}

/**
 * 
 * @param {{spouse: Spouse, onClose: () => void, onSave: (spouse: Spouse) => Promise<void>, onAdd: (spouse: Spouse) => Promise<void>, employers: {keyValue: any, code: any, jurisdictionCode: any}[], spouseHistory: SpouseHistory}} param0 
 * @returns 
 */
const SpouseForm = ({spouse, onClose, onSave, onAdd, employers, spouseHistory}) => { 

	const isNew = spouse.isNew();
	if (isNew) spouse.id = uuid();

	const [draft, _setDraft] = useState(spouse.clone());
	const [errors, setErrors] = useState({});
	// flag to show the question for confirmation before changing the marital status.
	// values: null | 'MarriedToSingle' | 'MarriedToDivorced' | 'MarriedToCommonLaw'
	const [showWarningMaritalStatusChange, setShowWarningMaritalStatusChange] = useState(null);
	// flag to indicate the question has been confirmed and we can save
	const [confirmedMaritalStatusChange, setConfirmedMaritalStatusChange] = useState(false);

	const setDraft = (draft) => _setDraft(draft.clone())
	const handleCancel = () => { onClose?.() }
	const onPersonSave = () => { spouse.person = draft.person }

	const render = () => setDraft(draft);

	const handlePersonChange = (person) => {
		draft.person = person;
		setDraft(draft);
	}

	const handleHasSINToggle = () => {
		draft.noSin = !draft.noSin;
		setDraft(draft);
	}

	const handleSelectEmployer = (key) => {
		draft.employer = employers.find(x=>x.keyValue === key);
		draft.jurisdiction = draft.employer.jurisdictionCode;
		setDraft(draft);
	}

	/**
	 * 
	 * @param {(spouse: Spouse) => Promise<void>} saveFn 
	 */
	const handleSave = (saveFn) => {
		const isValidResult = isValid();
		if (isValidResult) {
			if(!confirmedMaritalStatusChange) {
				const isMarriedToSingle = spouseHistory?.findSpouse(draft.jurisdiction)?.isMarried && (draft.isSingleNotUndefined);
				const isMarriedToDivorced= spouseHistory?.findSpouse(draft.jurisdiction)?.isMarried && (draft.isDivorced);
				const isMarriedToCL= spouseHistory?.findSpouse(draft.jurisdiction)?.isMarried && (draft.isCommonLaw);
				if(isMarriedToSingle) {
					// values: null | 'MarriedToSingle' | 'MarriedToDivorced' | 'MarriedToCommonLaw'
					setShowWarningMaritalStatusChange('MarriedToSingle');
					return;
				}
				else if(isMarriedToDivorced) {
					// values: null | 'MarriedToSingle' | 'MarriedToDivorced' | 'MarriedToCommonLaw'
					setShowWarningMaritalStatusChange('MarriedToDivorced');
					return;
				}
				else if(isMarriedToCL) {
					// values: null | 'MarriedToSingle' | 'MarriedToDivorced' | 'MarriedToCommonLaw'
					setShowWarningMaritalStatusChange('MarriedToCommonLaw');
					return;
				}
			}
			const preSaveDraft = SpouseService.preSave(draft);
			if(typeof saveFn === 'function'){
				saveFn(preSaveDraft).then(() => {
					onClose?.();
				});
			} else {
				onClose?.();
			}
		}
	}

	const isValid =  () => {
        const errors = SpouseService.validate(draft, spouseHistory.latest);
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return false;
        }
        return true;
    }

	const onConfirmMaritalStatusChange = () =>{
		// close warning
		setShowWarningMaritalStatusChange(false);
		// set confirmed
		setConfirmedMaritalStatusChange(true);
	}

	const onCloseMaritalStatusChange = () => {
		// revert changes to status
		draft.mSts = spouse.mSts;
		setDraft(draft);
		// close warning
		setShowWarningMaritalStatusChange(false);
		// set not confirmed
		setConfirmedMaritalStatusChange(false);
	}

	const hasMaritalBreakdown = draft.maritalBreakDownOption && draft.maritalBreakDownOption !== '';
	const check = <Check name='sin' value={draft.noSin} label='SIN Missing' lcn='checkbox-inline' onChange={handleHasSINToggle} />
	const employerOptions = employers.map(opt => ({key: opt.keyValue, text: opt.code, value: opt.jurisdictionCode}))

	return <>
		<Title title={(isNew ?'New ':'') + 'Marital Information'} onHide={handleCancel}/>
		<FieldSet title='Marital Status' className='mt-1 mb-1' variant='field'>
			<div className="grid-ellipsis gel-4">
				<EInput name='effDt' showRequiredLabelMarker onChange={render} instance={draft} error={errors.effDt} label={'Designation Date'}/>
				<EInput name='mSts' showRequiredLabelMarker onChange={render} error={errors.mSts}  instance={draft}/>
				<EInput name='jurisdiction' label="Employer" instance={draft} error={errors.jurisdiction} >
					<Select options={employerOptions} value={draft?.employer?.keyValue} onChange={handleSelectEmployer} readOnly={false}/>
				</EInput> 
				<EInput name='jurisdiction' showRequiredLabelMarker error={errors.jurisdiction} instance={draft} readOnly={true}/>
            </div>
		</FieldSet>
		{ (!draft.isSingle && !draft.noSin) && <PersonSummary errors={errors} 
			onSave={onPersonSave}
			title={<Row> Spouse {check}</Row>} 
			allowPersonChange={true} person={draft.person} onSwitchPerson={handlePersonChange} onNewPerson={handlePersonChange}/> 
		}
		{ (!draft.isSingle && draft.noSin)  && <UnknownPersonForm errors={errors} person={draft.unknownPerson} 
			title={<Row> Spouse {check}</Row>} 
			/> 
		}
			<FieldSet title='Marital Information' className='mt-1 mb-1' variant='field'>
			{ !draft.isSingle && <>
				<div className="grid-ellipsis">
					<EInput name='taxationStatus' instance={draft} />
					<EInput name='maritalBreakDownOption' onChange={render} instance={draft} />
					{ hasMaritalBreakdown && <EInput name='maritalBreakDownDate' instance={draft}/>}
				</div>
				{ hasMaritalBreakdown && <div className="grid-ellipsis">
					<EInput name='judgementOrderReceivedOption' instance={draft} />
					<EInput name='maritalStatusDeclarationReceived' instance={draft} />
					<EInput name='judgementOrderReceivedOption' instance={draft} />
				</div> }
			</> }
				<div className="line g10">
					<EInput variant="textarea" name='cmt' instance={draft}/>
				</div>
			</FieldSet>
		<Row className='justify-content-end button-row mt20'>
			<Button onClick={() => handleSave(isNew ? onAdd : onSave)}> {isNew ? 'Add':'Save'} </Button>
		</Row>
		{showWarningMaritalStatusChange &&  MaritalStatusChangeWarnings[showWarningMaritalStatusChange] &&
			<Question title={MaritalStatusChangeWarnings[showWarningMaritalStatusChange]}
				onAnswer={(answer) => {
					if(answer) {
						onConfirmMaritalStatusChange();
					}
					else {
						onCloseMaritalStatusChange();
					}
				}}
			/>
		}
	</>	
}

export default SpouseForm;