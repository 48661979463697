import React from "react";

import * as XLSX from 'xlsx';
import { Component } from '../../../framework';
import { UploadButton } from '../../../framework/controls';
import { Definition, Ref } from '../../../framework/infra'
import { Earnings, Earning }  from '../../../entities';import { isEmpty } from "../../../framework/utils/helper";
import { FIRST_LAST_VARIATIONS, FIRST_NAME_VARIATIONS, LAST_NAME_VARIATIONS, PPNO_VARIATIONS, SIN_VARIATIONS, cleanHeaderRow, errors, findHeaderPosition, findNameHeaderPosition, getEarningMappings } from "../../../framework/utils/excelImportHelper";
;

class UploadEarnings extends Ref {
    static definitions = {
        rowId: { type: Definition.types.NUMBER },
        earnings: { ref: Earnings, text: 'Earnings' },
        vol: { type: Definition.types.AMOUNT },
        sin: { type: Definition.types.STRING },
        firstName: { type: Definition.types.STRING },
        lastName: { type: Definition.types.STRING },
        cmt: { type: Definition.types.STRING },
        ppNo: { type: Definition.types.STRING },
    }
}

export default class EarningsImport extends Component {
    view() {
        return (
            <UploadButton
                buttonClassName="d-flex justify-content-center align-items-center ml-3 px-3"
                className={this.props.className}
                onUpload={this.handleUpload.bind(this)}
                text="Import Earnings"
                displayfileName={true}
                accept=".xls,.xlsx,.csv"
                fileName={this.props.fileName}
            />
        );
    }
   
    handleUpload(file) {
        const { earningTypes } = this.props
        const supportedTypes = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '']
        if(!file) {
            return this.error('noFileSelected') 
        } else if (!supportedTypes.includes(file?.type ?? '')) {
            console.error('EarningsImport handleUpload fileNotSupported', file?.type)
            return this.error('fileNotSupported') 
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const workbook = XLSX.read(e.target.result, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                const rows = XLSX.utils.sheet_to_json(worksheet, {header: 1});
                
                const headerPosition = findHeaderPosition(rows, ['sin', 'nas', 'ssn'])
                const nameHeaderPosition = findNameHeaderPosition(rows);
                if (headerPosition < 0) return this.error('noHeaderFound')
                const nameHeaders = cleanHeaderRow(rows[nameHeaderPosition])
                const headers = cleanHeaderRow(rows[headerPosition])
                const subHeaders = cleanHeaderRow(rows[headerPosition + 1])
                const earningMappings = getEarningMappings(earningTypes, headers, subHeaders)
                const sinIndex = headers.findIndex(header => (SIN_VARIATIONS.includes(header)))
                const firstNameIndex = nameHeaders.findIndex(header => FIRST_NAME_VARIATIONS.find(x=>header?.includes(x)))
                const lastNameIndex = nameHeaders.findIndex(header => LAST_NAME_VARIATIONS.find(x=>header?.includes(x)))
                const ppNoIndex = headers.findIndex(header => (PPNO_VARIATIONS.includes(header)))
                const firstAndLastIndex = nameHeaders.findIndex(header => { 
                    return FIRST_LAST_VARIATIONS.find(x=> {
                        return x.variation.find(y=> header?.includes(y))
                    }) 
                })
                const nameDecomposer = firstAndLastIndex >= 0 ? FIRST_LAST_VARIATIONS.find(x=> x.variation.find(y=> nameHeaders[firstAndLastIndex].includes(y))).decompose : null;
                
                const volIndex =  headers.findIndex(header => (header === 'voluntary'))
                const cmtIndex =  headers.findIndex(header => (header === 'comment' || header === 'comments'))
                
                //remove headers from rows
                rows.splice(0, headerPosition + 1)
                var count = headerPosition + 1;
                const details = rows.reduce((valides, row) => {
                    count++;
                    const sin = String(row[sinIndex]).trim().split('-').join('').split(' ').join('')
                    if (!isNaN(sin)) {
                        var firstName = firstNameIndex && row[firstNameIndex] ? row[firstNameIndex] : '';
                        var lastName = lastNameIndex && row[lastNameIndex] ? row[lastNameIndex] : '';

                        if (firstAndLastIndex >= 0 && isEmpty(firstName) && isEmpty(lastName)) {
                            var namePair = nameDecomposer(row[firstAndLastIndex]);
                            firstName = namePair.first;
                            lastName = namePair.last;
                        }

                        const detail = new UploadEarnings({
                            ppNo: row[ppNoIndex],
                            rowId: count,
                            sin,
                            firstName,
                            lastName,
                            vol: volIndex > 0 && row[volIndex] ? row[volIndex] : 0,
                            cmt: cmtIndex > 0 ? row[cmtIndex] : ''
                        })
                        
                        detail.earnings =  earningMappings.reduce((earnings, mapping) => {
                            const earning = new Earning({ 
                                code: mapping.earningType.code,
                                earningType: mapping.earningType,
                                amount: row[mapping.index]
                            })
                            if (mapping.hoursOrRate && row[mapping.index + 1]) {
                                const val = row[mapping.index + 1]
                                earning.hours = mapping.hoursOrRate === 'rate' ? row[mapping.index] / val : val
                            }
                            earnings.push(earning)
                            return earnings
                        }, new Earnings())
                        
                        valides.push(detail)
                    }
                    return valides
                }, [])
                this.props.onUpload && this.props.onUpload(details, file.name)
            
            } catch (err) {
                // Handle errors here, such as password-protected files
                console.log(err.message);
                this.error('fileReadError', err.message);
            }
            
        };
        reader.readAsBinaryString(file);
    }

    error(errorCode, message) {
        console.log(errorCode)
        if (this.props.onError)  {
            if (message) {
                this.props.onError({text: message})
                return;
            } else 
                this.props.onError(errors[errorCode] || { message: {text:errorCode} })
        }
    }

}
